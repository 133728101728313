import axios from 'axios'
import * as urlConfig from '@/urlConfig';

let baseURL = (location.origin || location.protocol + '//' + location.host) + '/api/';
if (process.env.NODE_ENV == 'development') {
    baseURL = urlConfig.dev + '/api/';
}

if (location.href.split('tag=').length == 2) {
    baseURL = (urlConfig[location.href.split('tag=')[1]] || urlConfig.dev) + '/api/';
}


export default axios.create({
  baseURL: baseURL, // api的base_url location.host
  // baseURL: 'http://192.168.2.30:8079/api/',
  showLoading: true,
  timeout: 600000
});