<template>
  <div>
    <PCNavbar selectIndex="1"/>
    <MobileNavBar   
    :current-active="currentActive" 
    @change-active="handleActiveChange"/>
    <!-- <CarouselHome /> -->
    <!-- <TodoShow/> -->
    <div class="bottom_circle" style="margin-top: 80px; border-radius: 10px;">
      <h1>START YOUR ENTRIES! Win ₹1 million at FUN-SVIP GAMING CENTER</h1>
      <!-- <div class="circle_img">
        <img src="@/assets/img/lineimg.9cc3174a.png" alt="" class="hidden-xs-only"/>
        <img src="@/assets/img/lineimg.9cc3174a.png" alt="" class="hidden-sm-and-up"/>
      </div> -->
    </div>
    <div class="game-detail">
        <div class="game-null"></div>
        <div class="game-number">Winning Numbers</div>
        <div class="game-time">{{ formatDate }}</div>
        <div class="messageNum">
            <div v-for="(item,index) in messageNumList" :key="index" :class="`btn2-${item.result}`"></div>
        </div>
        <div class="game-name">Fun Svip {{ chooseGameName }}</div>
        <div class="game-null"></div>
    </div>
    <div class="game-detail">
        <div class="game-null"></div>
        <div class="game-number">New Drawing</div>
        <div class="game-time">{{ formatDate }}</div>
        <div class="game-countdown">
            <div class="count-down-container" v-if="currentActive==0">
                <div class="block" v-for="item in dataTime" :key="item.id" style="display: flex">
                    <div class="time text">
                        <div class="top t-block">{{ formatNumber(currentTime[item.type]) }}</div>
                          <div class="flod-box" :ref="el => flodBoxNode[item.id] = el">
                              <div class="front flod-block">{{ formatNumber(nextTime(item.type)) }}</div>
                              <div class="back flod-block">{{ formatNumber(currentTime[item.type]) }}</div>
                          </div>
                        <div class="bottom t-block">{{ formatNumber(nextTime(item.type)) }}</div>
                    </div>
                    <div class="time-label">{{ item.text }}</div>
                </div>
            </div>
            <!-- 1min -->
            <div class="count-down-container" v-if="currentActive==1">
                <div class="block" v-for="item in dataTime1" :key="item.id" style="display: flex">
                    <div class="time text">
                        <div class="top t-block">{{ formatNumber1(currentTime1[item.type]) }}</div>
                          <div class="flod-box" :ref="el => flodBoxNode1[item.id] = el">
                              <div class="front flod-block">{{ formatNumber1(nextTime1(item.type)) }}</div>
                              <div class="back flod-block">{{ formatNumber1(currentTime1[item.type]) }}</div>
                          </div>
                        <div class="bottom t-block">{{ formatNumber1(nextTime1(item.type)) }}</div>
                    </div>
                    <div class="time-label">{{ item.text }}</div>
                </div>
            </div>
            <!-- 3min -->
            <div class="count-down-container" v-if="currentActive==2">
                <div class="block" v-for="item in dataTime2" :key="item.id" style="display: flex">
                    <div class="time text">
                        <div class="top t-block">{{ formatNumber2(currentTime2[item.type]) }}</div>
                          <div class="flod-box" :ref="el => flodBoxNode2[item.id] = el">
                              <div class="front flod-block">{{ formatNumber2(nextTime2(item.type)) }}</div>
                              <div class="back flod-block">{{ formatNumber2(currentTime2[item.type]) }}</div>
                          </div>
                        <div class="bottom t-block">{{ formatNumber2(nextTime2(item.type)) }}</div>
                    </div>
                    <div class="time-label">{{ item.text }}</div>
                </div>
            </div>
            <!-- 5min -->
            <div class="count-down-container" v-if="currentActive==3">
                <div class="block" v-for="item in dataTime3" :key="item.id" style="display: flex">
                    <div class="time text">
                        <div class="top t-block">{{ formatNumber3(currentTime3[item.type]) }}</div>
                          <div class="flod-box" :ref="el => flodBoxNode3[item.id] = el">
                              <div class="front flod-block">{{ formatNumber3(nextTime3(item.type)) }}</div>
                              <div class="back flod-block">{{ formatNumber3(currentTime3[item.type]) }}</div>
                          </div>
                        <div class="bottom t-block">{{ formatNumber3(nextTime3(item.type)) }}</div>
                    </div>
                    <div class="time-label">{{ item.text }}</div>
                </div>
            </div>
          <!-- <FlipClock   :remaining-time="remainingTime"/> -->
        </div>
        <div style="display: flex;justify-content: center;width: 80%;margin: 0 auto;">
            <div class="game-min">MINUTES</div>
            <div class="game-sec">SECONDS</div>
          </div>
    </div>
    <div class="game-detail">
        <div class="game-null"></div>
        <div class="game-number">Games‘ Numbers</div>
        <div class="game-time">{{ formatDate }}</div>
        <div class="game-info">
          <div class="gameInfo-item">
            <div class="item-title">Fun Svip 30s</div>
            <div class="item-number">
              <div :class="`btn2-${showGameInfo.wingo30Data.result}`"></div>
            </div>
          </div>
          <div class="gameInfo-item">
            <div class="item-title">Fun Svip 1Min</div>
            <div class="item-number">
              <div :class="`btn2-${showGameInfo.wingo1Data.result}`"></div>
            </div>
          </div>
        </div>
        <div class="game-info">
          <div class="gameInfo-item">
            <div class="item-title">Fun Svip 3Mins</div>
            <div class="item-number">
              <div :class="`btn2-${showGameInfo.wingo3Data.result}`"></div>
            </div>
          </div>
          <div class="gameInfo-item">
            <div class="item-title">Fun Svip 5Mins</div>
            <div class="item-number">
              <div :class="`btn2-${showGameInfo.wingo5Data.result}`"></div>
            </div>
          </div>
        </div>
    </div>
    <div class="how-to" @click="showPopover = !showPopover">
        HOW TO PLAY
        <img src="@/assets/newimg/down.png" alt="" v-if="!showPopover">
        <img src="@/assets/newimg/up.png" alt="" v-if="showPopover">
    </div>
    <div class="game-list" v-if="showPopover">
      <div class="game-item">
        <div class="game-title">Gamelayerfun</div>
        <div class="game-txt">Gamelayerfun click <a href="https://gamelayerfun.com" style="color:#DB1734;text-decoration: underline;">HERE</a></div>
      </div>
      <div class="game-item">
        <div class="game-title">Gamevyfun</div>
        <div class="game-txt">Gamevyfun click <a href="https://gamevyfun.com" style="color:#DB1734;text-decoration: underline">HERE</a></div>
      </div>
      <div class="game-item">
        <div class="game-title">Lulumallfun</div>
        <div class="game-txt">Lulumallfun click <a href="https://lulumallfun.com" style="color:#DB1734;text-decoration: underline">HERE</a></div>
      </div>
      <div class="game-item">
        <div class="game-title">Lotopopfun</div>
        <div class="game-txt">Lotopopfun click <a href="https://lotopopfun.com" style="color:#DB1734;text-decoration: underline">HERE</a></div>
      </div>
      <div class="game-item">
        <div class="game-title">Thelotterfun</div>
        <div class="game-txt">Thelotterfun click <a href="https://thelotterfun.com" style="color:#DB1734;text-decoration: underline">HERE</a></div>
      </div>
    </div>
    <div class="web-detail">
        <div class="web-title">
          <img src="@/assets/newimg/logofun.png" alt="">
        </div>
        <div class="web-text">The Fun-Svip Center makes every effort to ensure the accuracy and 
          timeliness of winning numbers and next drawings. You can check results 
          of multiple games in the one official website. Official winning numbers 
          are also recorded in each original gaming platform. In the event of a 
          discrepancy, the official drawing results shall prevail. 
        </div>
        <div class="web-text">©️ 2025 Fun-Svip Center. All Rights Reserved.</div>
    </div>
    <!-- <CoreCompetence/>
    <CooperationCustomer/>
    <NewsInfomation/>
    <CallMe/>
    <MobileCallme/> -->
  </div>
</template>

<script>
import PCNavbar from '@/components/navbar/index'
import MobileNavBar from '@/components/navbar/MobileNavBar.vue'
import CarouselHome from '@/components/carousel_home/index'
import TodoShow from '@/components/todo_show/index'
import CoreCompetence from '@/components/core_competence/index'
import CooperationCustomer from '@/components/cooperation_customer/index'
import NewsInfomation from '@/components/news_infomation/index'
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
import FlipClock from '@/components/FlipClock/index.vue'
import axios from '@/utils/http'
export default {
  components: {
    PCNavbar,
    MobileNavBar,
    CarouselHome,
    TodoShow,
    CoreCompetence,
    CooperationCustomer,
    NewsInfomation,
    CallMe,
    MobileCallme,
    FlipClock
  },
  data() {
    return {
      currentActive: 0,
      currentDate: new Date(),
      messageNumList:[],
      messageNumList1:[],
      messageNumList2:[],
      messageNumList3:[],
      chooseGameName:'',
      //remainingTime: 30 * 60 * 1000, // 30分钟
      //30s游戏
      totalSeconds: 0,  // 接口返回的剩余秒数
      currentTime: {
        minutes: 0,
        seconds: 0
      },
      dataTime: [
        { id: 0,  type: 'minutes' },
        { id: 1,  type: 'seconds' }
      ],
      flodBoxNode: [],
      timer:null,
      //1min游戏
      totalSeconds1: 0,  // 接口返回的剩余秒数
      currentTime1: {
        minutes: 0,
        seconds: 0
      },
      dataTime1: [
        { id: 0,  type: 'minutes' },
        { id: 1,  type: 'seconds' }
      ],
      flodBoxNode1: [],
      timer1:null,
      //3min游戏
      totalSeconds2: 0,  // 接口返回的剩余秒数
      currentTime2: {
        minutes: 0,
        seconds: 0
      },
      dataTime2: [
        { id: 0,  type: 'minutes' },
        { id: 1,  type: 'seconds' }
      ],
      flodBoxNode2: [],
      timer2:null,
      //5min游戏
      totalSeconds3: 0,  // 接口返回的剩余秒数
      currentTime3: {
        minutes: 0,
        seconds: 0
      },
      dataTime3: [
        { id: 0,  type: 'minutes' },
        { id: 1,  type: 'seconds' }
      ],
      flodBoxNode3: [],
      timer3:null,
      showPopover:false,
      showGameInfo:[]
    }
  },
  computed: {
      formatDate() {
          const options = { 
              weekday: 'short', 
              year: 'numeric', 
              month: 'short', 
              day: 'numeric' 
          };
          const dateString = this.currentDate.toLocaleDateString('en-US', options);
          return dateString.replace(',', ''); // 去掉默认的逗号
      }
  },
  watch: {
  },
  mounted() {
    this.getRecordList()
    // 这里模拟接口获取剩余秒数，假设接口返回的是 179 秒（2分59秒）
    // this.totalSeconds = 180
    this.getGameInfo()
    this.gameInfo()
  },
  beforeDestroy() {
      clearInterval(this.timer1)
      clearInterval(this.timer2)
      clearInterval(this.timer3)
      clearInterval(this.timer)
  },  
  methods: {
    handleActiveChange(newValue) {
      this.currentActive = newValue
      if(this.currentActive==0){
            this.getRecordList()
            this.getGameInfo()
            clearInterval(this.timer1)
            clearInterval(this.timer2)
            clearInterval(this.timer3)
        }else if(this.currentActive==1){
            this.getRecordList1()
            this.getGameInfo1()
            clearInterval(this.timer)
            clearInterval(this.timer2)
            clearInterval(this.timer3)
        }else if(this.currentActive==2){
            this.getRecordList2()
            this.getGameInfo2()
            clearInterval(this.timer)
            clearInterval(this.timer1)
            clearInterval(this.timer3)
        }else if(this.currentActive==3){
            this.getRecordList3()
            this.getGameInfo3()
            clearInterval(this.timer0)
            clearInterval(this.timer1)
            clearInterval(this.timer2)
        }
    },
    // 新增数字格式化方法
    formatNumber(num) {
      return num.toString().padStart(2, '0')
    },
    formatNumber1(num) {
      return num.toString().padStart(2, '0')
    },
    formatNumber2(num) {
      return num.toString().padStart(2, '0')
    },
    formatNumber3(num) {
      return num.toString().padStart(2, '0')
    },
    startCountDown() {
      // 先清除已有定时器
      if (this.timer) clearInterval(this.timer)
      
      this.timer = setInterval(() => {
        if (this.totalSeconds == 0) {
          clearInterval(this.timer)
          this.currentTime = { minutes: 0, seconds: 0 }
          this.getGameInfo()
          this.getRecordList()
          return
        }

        this.totalSeconds--
        const newTime = {
          minutes: Math.floor(this.totalSeconds / 60),
          seconds: this.totalSeconds % 60
        }

        if (newTime.minutes !== this.currentTime.minutes) {
          this.transform180(0)
        }
        if (newTime.seconds !== this.currentTime.seconds) {
          this.transform180(1)
        }

        this.currentTime = newTime
      }, 1000)
    },
    startCountDown1() {
      // 先清除已有定时器
      if (this.timer1) clearInterval(this.timer1)
      
      this.timer1 = setInterval(() => {
        if (this.totalSeconds1 == 0) {
          clearInterval(this.timer1)
          this.currentTime1 = { minutes: 0, seconds: 0 }
          this.getGameInfo1()
          this.getRecordList1()
          return
        }

        this.totalSeconds1--
        const newTime = {
          minutes: Math.floor(this.totalSeconds1 / 60),
          seconds: this.totalSeconds1 % 60
        }

        if (newTime.minutes !== this.currentTime1.minutes) {
          this.transform1801(0)
        }
        if (newTime.seconds !== this.currentTime1.seconds) {
          this.transform1801(1)
        }

        this.currentTime1 = newTime
      }, 1000)
    },
    startCountDown2() {
      // 先清除已有定时器
      if (this.timer2) clearInterval(this.timer2)
      
      this.timer2 = setInterval(() => {
        if (this.totalSeconds2 == 0) {
          clearInterval(this.timer2)
          this.currentTime2 = { minutes: 0, seconds: 0 }
          this.getGameInfo2()
          this.getRecordList2()
          return
        }

        this.totalSeconds2--
        const newTime = {
          minutes: Math.floor(this.totalSeconds2 / 60),
          seconds: this.totalSeconds2 % 60
        }

        if (newTime.minutes !== this.currentTime2.minutes) {
          this.transform1802(0)
        }
        if (newTime.seconds !== this.currentTime2.seconds) {
          this.transform1802(1)
        }

        this.currentTime2 = newTime
      }, 1000)
    },
    startCountDown3() {
      // 先清除已有定时器
      if (this.timer3) clearInterval(this.timer3)
      
      this.timer3 = setInterval(() => {
        if (this.totalSeconds3 == 0) {
          clearInterval(this.timer3)
          this.currentTime3 = { minutes: 0, seconds: 0 }
          this.getGameInfo3()
          this.getRecordList3()
          return
        }

        this.totalSeconds3--
        const newTime = {
          minutes: Math.floor(this.totalSeconds3 / 60),
          seconds: this.totalSeconds3 % 60
        }

        if (newTime.minutes !== this.currentTime3.minutes) {
          this.transform1803(0)
        }
        if (newTime.seconds !== this.currentTime3.seconds) {
          this.transform1803(1)
        }

        this.currentTime3 = newTime
      }, 1000)
    },
    // 新增倒计时结束处理方法
    nextTime(type) {
      const current = this.currentTime[type]
      if (type === 'minutes') {
        return current === 0 ? 0 : current 
      }
      return current === 59 ? 0 : current 
    },
    nextTime1(type) {
      const current = this.currentTime1[type]
      if (type === 'minutes') {
        return current === 0 ? 0 : current 
      }
      return current === 59 ? 0 : current 
    },
    nextTime2(type) {
      const current = this.currentTime2[type]
      if (type === 'minutes') {
        return current === 0 ? 0 : current 
      }
      return current === 59 ? 0 : current 
    },
    nextTime3(type) {
      const current = this.currentTime3[type]
      if (type === 'minutes') {
        return current === 0 ? 0 : current 
      }
      return current === 59 ? 0 : current 
    },
    transform180(nodeNumber) {
      const element = this.flodBoxNode[nodeNumber]
      if (!element) return
      
      element.style.transition = "transform 0s"
      element.style.transform = "perspective(400px) rotateX(0deg)"
      
      setTimeout(() => {
        element.style.transition = "transform 0.9s"
        element.style.transform = "perspective(400px) rotateX(-180deg)"
      }, 10)
    },
    transform1801(nodeNumber) {
      const element = this.flodBoxNode1[nodeNumber]
      if (!element) return
      
      element.style.transition = "transform 0s"
      element.style.transform = "perspective(400px) rotateX(0deg)"
      
      setTimeout(() => {
        element.style.transition = "transform 0.9s"
        element.style.transform = "perspective(400px) rotateX(-180deg)"
      }, 10)
    },
    transform1802(nodeNumber) {
      const element = this.flodBoxNode2[nodeNumber]
      if (!element) return
      
      element.style.transition = "transform 0s"
      element.style.transform = "perspective(400px) rotateX(0deg)"
      
      setTimeout(() => {
        element.style.transition = "transform 0.9s"
        element.style.transform = "perspective(400px) rotateX(-180deg)"
      }, 10)
    },
    transform1803(nodeNumber) {
      const element = this.flodBoxNode3[nodeNumber]
      if (!element) return
      
      element.style.transition = "transform 0s"
      element.style.transform = "perspective(400px) rotateX(0deg)"
      
      setTimeout(() => {
        element.style.transition = "transform 0.9s"
        element.style.transform = "perspective(400px) rotateX(-180deg)"
      }, 10)
    },
    gameInfo(){
        axios.get('/v1/gameresults/records')
          .then(response => {
            //console.log('获取到数字:', response)
            this.showGameInfo=response.data.data
        })
    },
    getGameInfo() {
      let params = { periodOfTime: 30 }
      axios.post('/v1/gameinfo/getgameinfo', params)
        .then(response => {
          const newSeconds = response.data.data.countdownSecond
          //console.log('获取到新时间:', newSeconds)
          
          // 立即更新显示
          this.totalSeconds = newSeconds
          this.currentTime = {
            minutes: Math.floor(newSeconds / 60),
            seconds: newSeconds % 60
          }
          // 只有时间大于0才启动倒计时
          if (newSeconds > 0) {
            this.startCountDown()
          }
        })
    },
    getGameInfo1() {
      let params = { periodOfTime: 60 }
      axios.post('/v1/gameinfo/getgameinfo', params)
        .then(response => {
          const newSeconds = response.data.data.countdownSecond
          //console.log('获取到新时间:', newSeconds)
          
          // 立即更新显示
          this.totalSeconds1 = newSeconds
          this.currentTime1 = {
            minutes: Math.floor(newSeconds / 60),
            seconds: newSeconds % 60
          }
          // 只有时间大于0才启动倒计时
          if (newSeconds > 0) {
            this.startCountDown1()
          }
        })
    },
    getGameInfo2() {
      let params = { periodOfTime: 180 }
      axios.post('/v1/gameinfo/getgameinfo', params)
        .then(response => {
          const newSeconds = response.data.data.countdownSecond
          //console.log('获取到新时间:', newSeconds)
          
          // 立即更新显示
          this.totalSeconds2 = newSeconds
          this.currentTime2 = {
            minutes: Math.floor(newSeconds / 60),
            seconds: newSeconds % 60
          }
          // 只有时间大于0才启动倒计时
          if (newSeconds > 0) {
            this.startCountDown2()
          }
        })
    },
    getGameInfo3() {
      let params = { periodOfTime: 300 }
      axios.post('/v1/gameinfo/getgameinfo', params)
        .then(response => {
          const newSeconds = response.data.data.countdownSecond
          //console.log('获取到新时间:', newSeconds)
          
          // 立即更新显示
          this.totalSeconds3 = newSeconds
          this.currentTime3 = {
            minutes: Math.floor(newSeconds / 60),
            seconds: newSeconds % 60
          }
          // 只有时间大于0才启动倒计时
          if (newSeconds > 0) {
            this.startCountDown3()
          }
        })
    },
    getRecordList(){
      this.chooseGameName='30s'
      let params={
        page:1,
        pageSize:10,
      }
      axios.post('/v1/gameresults/wingo30/record',params)
        .then(response => {
          //console.log('响应数据:', response.data)
          this.messageNumList=response.data.data.slice(0,5)
        })
        .catch(error => {
          console.error('请求失败:', error)
        })
    },
    getRecordList1(){
      this.chooseGameName='1Min'
      let params={
        page:1,
        pageSize:10,
      }
      axios.post('/v1/gameresults/wingo1/record',params)
        .then(response => {
          //console.log('响应数据:', response.data)
          this.messageNumList=response.data.data.slice(0,5)
        })
        .catch(error => {
          console.error('请求失败:', error)
        })
    },
    getRecordList2(){
      this.chooseGameName='3Min'
      let params={
        page:1,
        pageSize:10,
      }
      axios.post('/v1/gameresults/wingo3/record',params)
        .then(response => {
          //console.log('响应数据:', response.data)
          this.messageNumList=response.data.data.slice(0,5)
        })
        .catch(error => {
          console.error('请求失败:', error)
        })
    },
    getRecordList3(){
      this.chooseGameName='5Min'
      let params={
        page:1,
        pageSize:10,
      }
      axios.post('/v1/gameresults/wingo5/record',params)
        .then(response => {
          //console.log('响应数据:', response.data)
          this.messageNumList=response.data.data.slice(0,5)
        })
        .catch(error => {
          console.error('请求失败:', error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom_circle {
  width: 100%;
  text-align: center;
  background-color: #BFBFBF;
  color:#C8102E;
  border-radius: 10px;
  padding: 10px 15px;
  // background-image: url(../assets/img/smalldot.png),url(../assets/img/smalldot1.png);
  // background-position: 0 0,110% 150%;
  // background-repeat: no-repeat;
  // background-size: 400px;
  h1 {
    font-size: 18px;
    text-decoration: underline;
    // padding-bottom: 20px;
    // @media screen and (max-width: 768px) {
    //   font-size: 1.15rem;
    //   width: 60%;
    //   margin: 0 auto;
    // }
  }
  .circle_img {
    width: 100%;
    img {
      width: 70%;
      // @media screen and (max-width: 768px) {
      //   width: 100%;
      //   margin: 20PX 0;
      // }
    }
  }
 
}
.game-detail{
    width: 90%;
    margin: 15px auto;
    border-radius: 10px;
    box-shadow: 4px 5px 4px rgba(0, 0, 0, 0.2);
    background: linear-gradient(to bottom, #B5B9BF 36%, #fff 100%);
    .game-null{
      width: 70%;
      margin: 10px auto;
      height: 10px;
    }
    .game-number{
      width: 70%;
      background-color: #fff;
      border-radius: 10px;
      margin: 0 auto;
      padding: 10px 20px;
      display:flex;
      justify-content: center;
      align-items: center;
      color:#101820;
      font-size: 20px;
      font-weight: bold;
    }
    .game-time{
      width: 70%;
      display:flex;
      margin: 10px auto;
      justify-content: center;
      align-items: center;
      color:#101820;
      font-size: 18px;
      font-weight: bold;
    }
    .game-info{
      display: flex;
      justify-content: space-around;
      align-items: center;
      .gameInfo-item{
        width: 48%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        .item-title{
          width: 160px;
          height: 40px;
          font-size: 16px;
          color:#fff;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 20px;
          background-color: #E3070C;
          margin-bottom: 10px;
        }
        .item-number{
          width: 160px;
          display: flex;
          justify-content: center;
          align-items: center;
          .btn2-0{
              aspect-ratio: 1;
              background-image: url(../assets/newimg/0.png);
              background-size: cover;
              background-position: center;
              width: 40px;
              height: 40px;
              margin:auto
          }
          .btn2-1{
              aspect-ratio: 1;
              background-image: url(../assets/newimg/1.png);
              background-size: cover;
              background-position: center;
              width: 40px;
              height: 40px;
              margin:auto
          }
          .btn2-2{
              aspect-ratio: 1;
              background-image: url(../assets/newimg/2.png);background-size: cover;
              background-position: center;
              width: 40px;
              height: 40px;
              margin:auto
          }
          .btn2-3{
              aspect-ratio: 1;
              background-image: url(../assets/newimg/3.png);background-size: cover;
              background-position: center;
              width: 40px;
              height: 40px;
              margin:auto
          }
          .btn2-4{
              aspect-ratio: 1;
              background-image: url(../assets/newimg/4.png);background-size: cover;
              background-position: center;
              width: 40px;
              height: 40px;
              margin:auto
          }
          .btn2-5{
              aspect-ratio: 1;
              background-image: url(../assets/newimg/5.png);background-size: cover;
              background-position: center;
              width: 40px;
              height: 40px;
              margin:auto
          }
          .btn2-6{
              background-image: url(../assets/newimg/6.png);background-size: cover;
              background-position: center;
              width: 40px;
              height: 40px;
              margin:auto
          }
          .btn2-7{
              aspect-ratio: 1;
              background-image: url(../assets/newimg/7.png);background-size: cover;
              background-position: center;
              width: 40px;
              height: 40px;
              margin:auto
          }
          .btn2-8{
              aspect-ratio: 1;
              background-image: url(../assets/newimg/8.png);background-size: cover;
              background-position: center;
              width: 40px;
              height: 40px;
              margin:auto
          }
          .btn2-9{
              aspect-ratio: 1;
              background-image: url(../assets/newimg/9.png);background-size: cover;
              background-position: center;
              width: 40px;
              height: 40px;
              margin:auto
          }
        }
    }
    }
    
    .game-name{
      margin: 15px auto;
      border-radius: 20px;
      width: 200px;
      height: 40px;
      background-color: #E3070C;
      color: #fff;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .messageNum{
      width: 80%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      .btn2-0{
          aspect-ratio: 1;
          background-image: url(../assets/newimg/0.png);
          background-size: cover;
          background-position: center;
          width: 40px;
          height: 40px;
          margin:auto
      }
      .btn2-1{
          aspect-ratio: 1;
          background-image: url(../assets/newimg/1.png);
          background-size: cover;
          background-position: center;
          width: 40px;
          height: 40px;
          margin:auto
      }
      .btn2-2{
          aspect-ratio: 1;
          background-image: url(../assets/newimg/2.png);background-size: cover;
          background-position: center;
          width: 40px;
          height: 40px;
          margin:auto
      }
      .btn2-3{
          aspect-ratio: 1;
          background-image: url(../assets/newimg/3.png);background-size: cover;
          background-position: center;
          width: 40px;
          height: 40px;
          margin:auto
      }
      .btn2-4{
          aspect-ratio: 1;
          background-image: url(../assets/newimg/4.png);background-size: cover;
          background-position: center;
          width: 40px;
          height: 40px;
          margin:auto
      }
      .btn2-5{
          aspect-ratio: 1;
          background-image: url(../assets/newimg/5.png);background-size: cover;
          background-position: center;
          width: 40px;
          height: 40px;
          margin:auto
      }
      .btn2-6{
          background-image: url(../assets/newimg/6.png);background-size: cover;
          background-position: center;
          width: 40px;
          height: 40px;
          margin:auto
      }
      .btn2-7{
          aspect-ratio: 1;
          background-image: url(../assets/newimg/7.png);background-size: cover;
          background-position: center;
          width: 40px;
          height: 40px;
          margin:auto
      }
      .btn2-8{
          aspect-ratio: 1;
          background-image: url(../assets/newimg/8.png);background-size: cover;
          background-position: center;
          width: 40px;
          height: 40px;
          margin:auto
      }
      .btn2-9{
          aspect-ratio: 1;
          background-image: url(../assets/newimg/9.png);background-size: cover;
          background-position: center;
          width: 40px;
          height: 40px;
          margin:auto
      }
    }
}
.how-to{
  width: 90%;
  margin: 0 auto;
  background-color: #C8102E;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#fff;
  font-size: 20px;
  font-weight: bold;
  img{
    width: 14px;
    height: 8px;
    margin: 15px 10px;
  }
}
.game-list{
  width: 86%;
  margin: 0 auto 20px auto;
  background-color: #fff;
  box-shadow: 
    4px 0 4px rgba(0, 0, 0, 0.2),  /* 右侧阴影 */
    -4px 0 4px rgba(0, 0, 0, 0.2), /* 左侧阴影 */
    0 4px 4px rgba(0, 0, 0, 0.2);  /* 下方阴影 */
    .game-item{
      width: 80%;
      margin-left: 10px;
      .game-title{
        font-size: 20px;
        font-weight: bold;
      }
      .game-txt{
        font-size: 14px;
      }
    }
}
.game-countdown{
  width: 80%;
  margin:0 auto;
  height: 130px;
}
/* 保持原有样式不变 */
.count-down-container {
  display: flex;
  justify-content: center;
}

.block {
  margin:0 10px;
  position: relative;
  border-radius: 10px;
}

.time {
  position: relative;
  width: 100px;
  height: 64px;
  box-sizing: border-box;
}

.text {
  font-size: 64px;
  color: #FFFFFF;
  text-align: center;
  font-weight: 800;
  line-height: 128px;
  border-radius: 10px;
}

.time .top {
  position: relative;
  border-bottom: 1px solid #fff;
}

.time .bottom {
  line-height: 0;
  border-top: 1px solid #fff;
}

.time .t-block {
  width: 100%;
  height: 100%;
  background-color: black;
  overflow: hidden;
  border-radius: 10px;
}

.flod-box {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #fff;
  transform-style: preserve-3d;
  transform-origin: bottom;
  border-radius: 10px;
}

.flod-block {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  overflow: hidden;
  border-radius: 10px;
}

.front {
  background-color: black;
  border-bottom: 1px solid #fff;
  border-radius: 10px;
}

.back {
  background-color: black;
  border-top: 1px solid #fff;
  transform: rotateX(0deg) rotateY(-180deg) scale(-1);
  line-height: 0px;
  border-radius: 10px;
}

.time-label {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  color: #fff;
  border-radius: 10px;
}
.game-min{
  width: 42%;
  display: flex;
  justify-content: center;
  color:#DB1734;
  font-size: 16px;
}
.game-sec{
  width: 42%;
  display: flex;
  justify-content: center;
  color:#DB1734;
  font-size: 16px;
}
.web-detail{
  width: 90%;
  margin: 20px auto;
  .web-title{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img{
      width: 195px;
      height: 30px;
    }
    .web-text{
      font-size: 14px;
      color:#101820
    }

  }
}
</style>